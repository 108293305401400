<template>
  <div class="">
    <custom-alert v-if="displayAlert===true" :message="alertMessage" :variant="alertVariant" v-on:showedAlert="resetAlert"></custom-alert>
    <div class="">
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
              <div class="card">
                <div class="card-body table-responsive table-head">
                  <div class="row bor-bot">
                    <div class="col-md-8 pl-0 pb-3">
                      <h1 class="m-0 text-dark pt-2 pb-1">Financials Reports</h1>
                    </div>
                    <div class="col-md-4">
                      <div class="text-right pb-3">
                        <ol class="breadcrumb float-sm-right">
                          <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                          <li class="breadcrumb-item">
                            <Breadcrumbs/>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>

                  <b-modal id="modal-member-report" v-bind:hide-footer="true" ref="modal-member-report" size="lg"
                           title="Advanced Search">
                           <p style="margin-top: -20px;margin-bottom: 30px "><strong class="text-primary">Note:</strong> <span class=" text-muted">Press Ctrl+click for multiple selection filters</span></p>
                    <form ref="form" method="post" @submit.prevent="advancedSearch();">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="transaction_start_date" class="form-label">Transaction From Date</label>
                            <div class="input-group birthdate">
                              <div class="birthdaypicker">
                                <date-pick
                                    v-model="searchForm.transaction_start_date"
                                    :format="'MM/DD/YYYY'"
                                    :displayFormat="'MM/DD/YYYY'"
                                    v-mask="'##/##/####'"
                                ></date-pick>
                              </div>
                            </div>
                            <input id="transaction_start_date" type="hidden" class="form-control"
                                   :value="transaction_start_date_1">
                            <div class="text-sm text-danger">{{ errors.transaction_start_date }}</div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="transaction_end_date" class="form-label">Transaction To Date</label>
                            <div class="input-group birthdate">
                              <div class="birthdaypicker">
                                <date-pick
                                    v-model="searchForm.transaction_end_date"
                                    :format="'MM/DD/YYYY'"
                                    :displayFormat="'MM/DD/YYYY'"
                                    v-mask="'##/##/####'"
                                ></date-pick>
                              </div>
                            </div>
                            <input id="transaction_end_date" type="hidden" class="form-control"
                                   :value="transaction_end_date_1">
                            <div class="text-sm text-danger">{{ errors.transaction_end_date }}</div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Transaction Id</label>
                            <input type="text" id="transaction_id" class="form-control"
                                   v-model="searchForm.transaction_id">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Member Id</label>
                            <input type="text" id="confirmation_code" class="form-control"
                                   v-model="searchForm.confirmation_code">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Name</label>
                            <input type="text" id="name" class="form-control" v-model="searchForm.name">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Email</label>
                            <input type="text" id="email" class="form-control" v-model="searchForm.email">
                          </div>
                        </div>
                        <div class="col-md-6">
                        <div class="form-group">
                          <label for="product" class="form-label">Product</label><br>
                          <Select2 id="product" name="product[]" @change="onChangeProduct" :options="products" v-model="searchForm.product" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />                         
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="membership_id"  class="form-label">Memberships</label><br>
                          <select id="membership_id" name="membership_id" class="form-control" v-model="searchForm.membership_id" :disabled="disableMembershipFilter">
                            <option value="">All Memberships</option>
                            <option v-for="(name, id) in membershipData" :value="name" :key="id">{{ name }}</option>
                          </select>
                        </div>
                      </div>
                        <div class="col-md-6" v-if="subdomain == 'waterski'">
                          <div class="form-group">
                            <label for="financials_class" class="form-label">Class</label><br>
                            <select id="financials_class" name="financials_class" class="form-control"
                                    v-model="searchForm.financials_class">
                              <option value="">Select</option>
                              <option>2043</option>
                              <option>2044</option>
                              <option>2061/3012</option>
                              <option>3020</option>
                              <option>3034</option>
                              <option>3036</option>
                              <option>3035</option>
                              <option>3040</option>
                              <option>3066</option>
                              <option>3212</option>
                              <option>3274</option>
                              <option>3279</option>
                              <option>3310</option>
                              <option>4018</option>
                              <option>4019</option>
                              <option>2057/3012</option>
                              <option>3214</option>
                            </select>
                          </div>
                        </div>
                        <!-- <div class="col-md-6">
                          <div class="form-group">
                            <label for="financials_sub_class"  class="form-label">Sub-Class</label><br>
                            <select id="financials_sub_class" name="financials_sub_class" class="form-control" v-model="searchForm.sub_class">
                              <option value="">Select</option>
                              <template v-for="(id, val) in financialSubClass">
                                <option v-if="val > 0" :value="val" :key="val">{{ id }}</option>
                              </template>
                            </select>
                          </div>
                        </div> -->

                          <div v-if="subdomain == 'alliancefastpitch'" class="col-md-6">
                            <div class="form-group">
                              <label for="league" class="form-label">Leagues </label>
                              <select class="form-control" v-model="searchForm.league" id="league"
                                      @change="getTeam()">
                                <option value="">Select</option>
                                <option v-for="(leagueData,key) in teamLeague" :key="key" :value="leagueData.id">
                                  {{ leagueData.name }}
                                </option>
                              </select>
                              <span class="text-sm text-danger"></span>
                            </div>
                          </div>
                          <div v-if="subdomain == 'alliancefastpitch'" class="col-md-6">
                            <div class="form-group">
                              <label for="age_division" class="form-label">Division </label>
                              <select class="form-control" @change="getTeam()" id="age_division"
                                      v-model="searchForm.age_division" name="playerTransferForm.age_division">
                                <option value="">Select</option>
                                <option v-for="(listOfAgeDivision,key) in ageDivisionOPt" :key="key"
                                        :name="listOfAgeDivision.text" :value="listOfAgeDivision.id">
                                  {{ listOfAgeDivision.text }}
                                </option>
                              </select>
                              <span class="text-sm text-danger"></span>
                            </div>
                          </div>
                          <div v-if="subdomain == 'alliancefastpitch'" class="col-md-6">
                            <div class="form-group">
                              <label for="leagueTeam" class="form-label">Team</label>
                              <select class="form-control" id="leagueTeam" v-model="searchForm.team">
                                <option value="">Select</option>
                                <option v-for="(TeamData,key) in leagueTeam" :key="key" :value="TeamData.id">
                                  {{ TeamData.text }}
                                </option>
                              </select>
                              <span class="text-sm text-danger"></span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="couponCode" class="form-label">Coupon Code</label>
                              <select class="form-control" id="couponCode" v-model="searchForm.coupon_code">
                                <option value="">Select</option>
                                <option v-for="(coupon,key) in couponCode" :key="key" :value="coupon.id">
                                  {{ coupon.text }}
                                </option>
                              </select>
                              <span class="text-sm text-danger"></span>
                            </div>
                          </div>
                        <div class="col-md-12 modal-searchbtn text-center">
                          <div class="form-group">
                            <button type="submit" class="btn btn-primary btn-blue">{{ button.SearchButton }}</button>
                          </div>
                          <div class="text-sm text-danger">{{ errors.not_found }}</div>
                        </div>
                      </div>
                    </form>
                  </b-modal>

                  <div class="table-responsive mt-3">
                    <div class="data-tablebutn pb-3">
                      <b-dropdown text="Left align" variant="primary" class="actions-dropdown mr-1" size="md" v-if="actionData.length">
                        <template #button-content>
                          <i class="fas fa-tasks"></i> Actions
                        </template>
                        <b-dropdown-item href="#" @click="chargeBack"><i class="fas fa-undo mr-2"></i> Chargeback</b-dropdown-item>
                      </b-dropdown>
                      <b-button class="btn-primary btn-darkblue btn mr-1" :class="{ 'filter-applied': filtersApplied }"
                                v-b-modal.modal-member-report>
                        <i class="fas fa-sliders-h"></i> Filters
                      </b-button>
                      <b-button class="btn-primary btn-darkblue btn mr-1" @click="refreshList" v-if="filtersApplied">
                        <i class="fas fa-redo"></i> Reset
                      </b-button>
                      <b-button class="btn-primary btn-darkblue btn" @click="exportCsv" :disabled="exportProgress">
                        <i class="fas" :class="[ exportProgress ? 'fa-circle-notch fa-spin' : 'fa-file-download' ]"></i>
                        Export
                      </b-button>
                    </div>
                    <table class="table table-sm table-bordered table-hover" id="financialTable">
                      <thead class="thead-dark">
                      <tr>
                        <th class="all">&nbsp;</th>
                        <th class="all">Transaction Date</th>
                        <th class="all">Transaction Id</th>
                        <th class="all">Member Id</th>
                        <th class="all">Name</th>
                        <th class="all">E-Mail</th>
                        <th class="all">Product</th>
                        <th class="all">Class</th>
                        <th class="none">Card Holder Name</th>
                        <th class="none">Coupon Code</th>
                        <th class="all">Cost</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr><td colspan="12" class="text-center">Please wait.... <i class="fa fa-circle-notch fa-spin"></i></td></tr>
                      </tbody>
                      <tfoot>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Subtotal:</td>
                        <td><span id="subTotal">$0</span></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total for Search (Net):</td>
                        <td><strong id="netTotal">$0</strong></td>
                      </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import CustomAlert from "../CustomAlert";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"

import $ from 'jquery';
import DownloadExcel from "vue-json-excel";
import moment from 'moment';
import axios from 'axios';

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

let cachedData = {};

pdfMake.vfs = pdfFonts.pdfMake.vfs;
window.pdfMake = pdfMake;
import jsZip from 'jszip';

window.JSZip = jsZip;

const validateName = name => {
  if (name.length || name != "") {
    return {valid: true, error: null};
  }
  return {valid: false, error: null};
};

export default {
  name: "Financial",
  components: {CustomAlert},
  data() {
    return {
      exportProgress: false,
      filtersApplied: false,
      table: {},
      products:['Membership','Donation'],
      searchForm: {
        transaction_start_date: '',
        transaction_end_date: '',
        transaction_id: '',
        status: '',
        confirmation_code: '',
        name: '',
        email: '',
        membership_id: '',
        product: '',
        financials_class: '',
        sub_class: '',
        league: '',
        team: '',
        age_division: '',
        coupon_code: ''
      },
      disableMembershipFilter: false,
      button: {
        SearchButton: 'Search'
      },
      teamLeague: {},
      leagueTeam: [],
      ageDivisionOPt: [
        {"id": "4", "text": "12U"},
        {"id": "5", "text": "10U"}
      ],
      disciplines_list:[],
      couponCode: [],
      couponCodeList: [],
      actionData: []
    }
  },
  methods: {
    exportCsv(event) {
      var self = this;
      $.ajax({
        url: process.env.VUE_APP_URL + "api/reports/export/financials-list",
        type: 'post',
        "data": this.table.ajax.params(),
        beforeSend: function () {
          self.exportProgress = true;
        },
        "success": function (res, status, xhr) {
          self.exportProgress = false;
          if (res.file_url != undefined) window.open(res.file_url, '_blank');
        },
        error: function (err) {
          console.log(err);
          self.exportProgress = false;
        }
      });
    },
    advancedSearch() {
      if (!this.validate()) {
        return;
      }
      console.log(this.button.SearchButton);
      this.button.SearchButton = 'Searching...';
      console.log(this.button.SearchButton);
      this.loaded = false;
      this.table.draw();
      this.filtersApplied = true;
      this.$refs['modal-member-report'].hide();
      this.button.SearchButton = 'Search';
    },
    validate() {
      this.errors = {}
      this.valid = false;
      const validStartDate = validateName(this.searchForm.transaction_start_date);
      this.errors.transaction_start_date = validStartDate.error;

      const validEndDate = validateName(this.searchForm.transaction_end_date);
      this.errors.transaction_end_date = validEndDate.error;

      var dateValid = false;
      if (validStartDate.valid && !this.validateDate(this.searchForm.transaction_start_date)) {
        dateValid = true;
        this.errors.transaction_start_date = "Invalid Date";
      }
      if (validEndDate.valid && !this.validateDate(this.searchForm.transaction_end_date)) {
        dateValid = true;
        this.errors.transaction_end_date = "Invalid Date";
      }

      if (dateValid == false) {
        if ((validStartDate.valid && validEndDate.valid) && (moment(this.searchForm.transaction_end_date).isBefore(this.searchForm.transaction_start_date))) {
          this.errors.transaction_end_date = 'End date must be greater than Start date!';
          return this.valid = false;
        } else {
          this.valid = true;
        }
      } else {
        this.valid = false;
      }

      return this.valid;
    },
    refreshList(event) {
      this.searchForm = {
        transaction_start_date: '',
        transaction_end_date: '',
        transaction_id: '',
        status: '',
        confirmation_code: '',
        name: '',
        email: '',
        membership_id: '',
        product: '',
        financials_class: '',
        sub_class: '',
        league: '',
        team: '',
        age_division: '',
        coupon_code: ''
      };
      this.table.ajax.reload();
      this.filtersApplied = false;
    },
    onChangeProduct(event) {
      this.disableMembershipFilter = false;
      if(event.length) {
        if (event[0] == "Membership") {
          this.disableMembershipFilter = false;
        } else {
          this.searchForm.membership_id = "";
          this.disableMembershipFilter = true;
        }
      }
    },
    productsAdd(){
      if(this.subdomain == 'waterski') {
        this.products.push('Donation-Foundation','Donation-Sport Disc','Background Screening','International Background Screening','Club Insurance','Merchandise Order','Merchandise Shipping','MVR Review','Tech/Processing Fee','Event Sanctioning','Basic Skills', 'Event Registration','Refund','Coupon', 'Chargeback');
      }
      else if(this.subdomain == 'alliancefastpitch') {
        this.products.push('AGL', 'USSSA', 'Insurance', 'Tech/Processing Fee', 'Stripe Charge','Refund','Coupon');
      } else {
        this.products.push('Background Screening','International Background Screening','Refund','Coupon');
      }      
    },
    getLeagues() {
      axios.get(this.basePath + "api/league")
          .then(response => {
            this.teamLeague = response.data.data;
          })
          .catch(err => {
            // Manage the state of the application if the request
            // has failed
          })
    },
    getTeam() {
      let leagueId = this.searchForm.league;
      let teams = [];
      this.teamLeague.forEach(league => {        
        if(this.searchForm.league == league.id)
        {
          teams = league.team;
        }        
      });
      
      this.leagueTeam = [];
      teams.forEach(code => {
        if(this.searchForm.age_division == code.age_division){
          this.leagueTeam.push({ id: code.id, text: code.name });
        }
      });
    },
    getDiscipline() {
      axios.get(this.basePath + "api/getDisciplines")
          .then(response => {
            this.disciplines = response.data.data;
            let discipline_codes = Object.keys(this.disciplines);
            if (discipline_codes.length && this.disciplines_list.length == 0) {
              discipline_codes.forEach(code => {
                this.disciplines_list.push({
                  id: code,
                  text: this.disciplines[code] == "NA - N/A" ? "N/A" : this.disciplines[code]
                });
              });
            }
            this.ageDivisionOPt = this.disciplines_list;
          })
          .catch(err => {
            // Manage the state of the application if the request
            // has failed
          })
    },
    getCoupon() {
      axios
          .get(process.env.VUE_APP_URL + "api/coupons-list")
          .then((response) => {
            this.couponCodeList = response.data.data;
            let coupons_codes = Object.keys(this.couponCodeList);
            if (coupons_codes.length && this.couponCode.length == 0) {
              coupons_codes.forEach(code => {
                this.couponCode.push({
                  id: code,
                  text: this.couponCodeList[code] == "NA - N/A" ? "N/A" : this.couponCodeList[code]
                });
              });
            }
            this.loaded = true;
          })
          .catch((error) => (this.errors = error.response.data));
    },
    rowSelectTrigger(){
      let self = this;
      $(document).on("change", ".row-checkbox", function () {
        //Select/Deselect related transactions
        let transaction_id = $(this).data('transaction');
        if (transaction_id !== undefined) {
          $('.row-checkbox[data-transaction="' + transaction_id + '"]').prop("checked", $(this).is(":checked"));
        }
        //Collect checked data
        let rowCheckbox = $('.row-checkbox:checked');
        self.actionData = [];
        if (rowCheckbox.length) {
          rowCheckbox.each(function () {
            self.actionData.push($(this).val());
          });
        }
      });
    },
    chargeBack(event){
      this.$bvModal.msgBoxConfirm("Are you certain you want to proceed with the chargeback for this transaction ?", {
        size: "md",
        centered: true,
        okTitleHtml: "<i class='fa fa-check mr-2'></i>Yes, Chargeback"
      })
          .then(value => {
            if (value) {
              axios.post(this.basePath+ "api/transactions/chargeback", { transactions: this.actionData }, {headers: this.adminHeaders})
                  .then(res => {
                    this.$bvModal.hide("transactionDetails");
                    if (res.data.status === "success") {
                      this.alertMessage = res.data.message;
                      this.table.draw();
                    } else {
                      this.alertMessage = res.data.message;
                      this.alertVariant = "danger";
                    }
                    this.displayAlert = true;
                    this.actionData = [];
                  })
                  .catch(err => {
                    console.log(err);
                    this.alertMessage = JSON.stringify(err);
                    this.alertVariant = "danger";
                    this.displayAlert = true;
                  });
            }
          })
          .catch(err => {
            console.log(err);
          });
    }
  },
  mounted() {
    this.getMembership();
    this.getFinancialClass();
    this.getFinancialSubClass();
    this.getMembershipStatus();
    this.getLeagues();
    this.getDiscipline();
    this.getCoupon();

    var self = this;
    this.table = $("#financialTable").DataTable({
      // dom: "<'row mt-2'<'col-sm-12 col-md-4'l><'col-sm-12 col-md-4'B>"+
      //     "<'col-sm-12 col-md-4'f>><'row'<'col-sm-12'tr>>" +
      //     "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      buttons: [
        // {
        //   extend: 'excel',
        //   className: 'btn-primary btn-darkblue btn',
        //   filename:"financial report",
        //   title: "Financial report",
        //   exportOptions: {
        //     modifier: { search: 'applied', order: 'applied' }
        //   }
        // },
        // {
        //   extend: 'csv',
        //   text: '<i class="fas fa-file-csv"></i> Export',
        //   className: 'btn-primary btn-darkblue btn',
        //   filename:"financial report",
        //   title: "Financial report",
        //   // action: function (e, dt, node, config) {
        //   //     $.ajax({
        //   //         url: process.env.VUE_APP_URL + "api/report/financial/Individual",
        //   //         type: 'post',
        //   //         "data": dt.ajax.params(),
        //   //         "success": function(res, status, xhr) {
        //   //             var csvData = new Blob([res], {type: 'text/csv;charset=utf-8;'});
        //   //             var csvURL = window.URL.createObjectURL(csvData);
        //   //             var tempLink = document.createElement('a');
        //   //             tempLink.href = csvURL;
        //   //             tempLink.setAttribute('download', 'data.csv');
        //   //             tempLink.click();
        //   //         }
        //   //     });
        //   // }
        // },
        // {
        //   extend: 'pdf', className: 'btn-primary btn-darkblue btn', orientation: 'landscape',
        //   pageSize:'legal', filename:"financial report", title: "Financial report"
        // },
        /*{
          text: 'Custom Btn',
          className: 'btn-primary btn-darkblue btn',
          action: function ( e, dt, node, config ) { lunchOut() }
        },*/
      ],
      processing: true,
      serverSide: true,
      bFilter: true,
      responsive: true,
      ajax: {
        url: process.env.VUE_APP_URL + "api/report/financial",
        type: 'post',
        data: function (d) {
          d.confirmation_code = $('#confirmation_code').val() || self.searchForm.confirmation_code;
          // d.first_name = $('#first_name').val() || self.searchForm.first_name;
          // d.last_name = $('#last_name').val() || self.searchForm.last_name;
          d.name = $('#name').val() || self.searchForm.name;
          d.email = $('#email').val() || self.searchForm.email;
          // d.status = $('#status').val() || self.searchForm.status;
          d.transaction_id = $('#transaction_id').val() || self.searchForm.transaction_id;
          d.transaction_start_date = $("#transaction_start_date").val() || self.searchForm.transaction_start_date;
          d.transaction_end_date = $("#transaction_end_date").val() || self.searchForm.transaction_end_date;
          d.product = $('#product').val() || self.searchForm.product;
          d.membership = $('#membership_id').val() || self.searchForm.membership_id;
          // d.financial_class = $('#financials_class').val() || self.searchForm.financials_class;
          // d.financial_subclass = $('#financials_sub_class').val() || self.searchForm.financials_sub_class;

          d.class = $('#financials_class').val() || self.searchForm.financials_class;
          d.league = $('#league').val() || self.searchForm.league;
          d.ageDivision = $('#age_division').val() || self.searchForm.age_division;
          d.leagueTeam = $('#leagueTeam').val() || self.searchForm.leagueTeam;
          d.coupon_code = $('#couponCode').val() || self.searchForm.coupon_code;
        }
      },
      columns: [
        {data: "row_select", name: "row_select", sortable: false, width: 10, render: (data, type, row) => {
          if (['refund','chargeback', 'coupon'].indexOf(row.transaction_type) === -1) {
            return `<input type="checkbox"
                        class="row-checkbox"
                        value="${row.id}"
                        data-type="${row.transaction_type}"
                        data-transaction="${row.transaction_id}"
                        ${row.row_select ? "" : "disabled"}>`;
          } else {
            return "";
          }
        }},
        {data: "transaction_date", name: "transaction_date"},
        {
          data: "transaction_id", name: "transaction_id", render: (data) => {
            return data || "N/A";
          }
        },
        {data: 'confirmation_code', name: 'confirmation_code'},
        {
          data: "name", name: "name", render: (data) => {
            return data || "N/A";
          }
        },
        {
          data: "email", name: "email", render: (data) => {
            return '<a class="email-link" href="mailto:' + data + '">' + data + '</a>' || "N/A";
          }
        },
        {data: "product", name: "product"},
        {data: "class", name: "class", visible: false},
        {data: "name_on_card", name: "name_on_card"},
        {data: "coupon_id", name: "coupon_id"},
        {data: "cost", name: "cost", render: data => '$' + parseFloat(data).toFixed(2)}
      ],
      order: [[1, "desc"]],

      columnDefs: [
        { targets: [0], visible: this.subdomain === 'waterski' },
      ],

      "footerCallback": function (row, data, start, end, display) {
        var api = this.api();

        // Remove the formatting to get integer data for summation
        var intVal = i => {
          return typeof i === 'string' ? i.substring(1) : typeof i === 'number' ? i : 0;
        };

        // Total over all pages
        var total = api.column(10).data().reduce(function (a, b) {
          return parseFloat(a) + parseFloat(b);
        }, 0);

        // Update footer
        $("#subTotal").html(
            '$' + total.toFixed(2)
        );
        $("#netTotal").html(
            '$' + api.ajax.json().overallTotal
        );
      }
    });

    if (this.subdomain == "waterski") {
      var column = this.table.column(7);
      column.visible(true);
    }
    this.productsAdd();
    this.rowSelectTrigger();
  },
  computed: {
    transaction_start_date_1: function () {
      return this.searchForm.transaction_start_date;
    },
    transaction_end_date_1: function () {
      return this.searchForm.transaction_end_date;
    }
  }
}
</script>

<style>
.actions-dropdown .btn{
  background-color: #05213e !important;
  border-color: #05213e !important;
}
</style>
